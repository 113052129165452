<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >

      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0 " class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import PreviewImage from '@/components/PreviewImage'
import { findProductSpecificationList } from '@/api/product_specification'

export default {
  name: 'ProductSpecificationList',
  components: {
    Pagination,
    PreviewImage
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      previewVisible: false,
      previewImages: [],
      pagination: {
        total_count: 0
      }
    }
  },
  props: {
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '规格名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '价格',
          dataIndex: 'str_price'
        },
        {
          title: '图片',
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findProductSpecificationList(
        Object.assign({}, this.query, { product_id: this.record.id })
      ).then((res) => {
        if (res.code === 0) {
          this.productId = this.record.id
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
