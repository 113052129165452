import request from '@/utils/request'

// 查询产品规格
export function findProductSpecificationList(params) {
  return request({
    url: `/product_specifications`,
    method: 'get',
    params: params
  })
}

// 产品规格排序列表
export function findProductSpecificationSortList(params) {
  return request({
    url: `/product_specifications/sort`,
    method: 'get',
    params: params
  })
}

// 创建产品规格
export function createProductSpecification(data) {
  return request({
    url: `/product_specifications`,
    method: 'post',
    data
  })
}

// 删除产品规格
export function deleteProductSpecification(id) {
  return request({
    url: `/product_specifications/${id}`,
    method: 'delete'
  })
}

// 售罄产品规格
export function sellOutProductSpecification(id) {
  return request({
    url: `/product_specifications/${id}/sell_out`,
    method: 'patch'
  })
}

// 恢复产品规格
export function onlineProductSpecification(id) {
  return request({
    url: `/product_specifications/${id}/online`,
    method: 'patch'
  })
}

// 产品规格选择框
export function findProductSpecificationOptions(params) {
  return request({
    url: `/product_specifications/options`,
    method: 'get',
    params: params
  })
}

